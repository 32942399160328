$crmBackGroundColorMain: #337ab7;
$crmBackGroundColor01: #008ab3e0;
$crmFontColor01: #f7f7f7;
$crmFontColor02: #000000;
$crmFontColor03: #303030;
$crmFontColor04: #2f78b9;
$crmFontColor05: rgba(141, 158, 163, 0.82);
$crmFontColor06: rgba(103, 152, 147, 0.82);

$btnFontColor: $crmBackGroundColorMain;
$btnBorder: 1px solid #cccccc;
$btnBgColor: #f1f1f1;

$dataGridCellFontColor: $crmFontColor03;
$dataGridHeaderBgColor: #fbfcfd;
$dataGridHeaderFontColor: $crmFontColor02;
$dataGridFontColor: $crmFontColor02;
$dataGridBgEvenColor: #fff;
$dataGridBgOddColor: #fbfcfd;
$dataGridSelectedBgColor: #eeeded;
$dataGridSelectedFontColor: $crmFontColor02;
$dataGridCheckBoxBgColor: $crmFontColor01;
$dataGridCheckBoxFontColor: $crmFontColor01;
$dataGridActionButtonColor: $crmBackGroundColorMain;

$gridContainerBorderColor: #f5f5f5;
$gridContainerBgColor: #fff;

$pagingPerPageBackground: #f8f7f7;
$pagingPerPageColor: $crmFontColor01;
$pagingInfoColor: $crmFontColor02;

$btnToolbarActionFontColor: #e4edf3;
$btnToolbarFontColor: #000000;
$btnToolbarBackgroundColor: #f8f7f7;
$btnToolbarHoverColor: #449bea;
$btnToolbarActiveColor: #3375b1;
$btnToolbarFocusColor: #2b76b9;
$btnToolbarDisableBackgroundColor: #eef2f5c7;
$btnToolbarDisableFontColor: #b3bbc1;

$red: #FE0101;
$red2: #f24646;